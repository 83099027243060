import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { isEmpty } from '~/utils';
import { useSearchParams } from 'react-router-dom';
import { Listbox } from '~/components';
import { ErrorBoundary, SubcategoryErrorComponent, } from '~/components/ErrorBoundary';
import Searchbar from '~/components/Searchbar/Searchbar';
import Subcategory from './components/Subcategory';
const fetchFilterData = async (contentTypeIds, searchItem, country, industry) => {
    try {
        const res = await fetch(`/api/v1/mo/filters?contentTypes=${contentTypeIds}&q=${searchItem}${country ? `&country=${country}` : ''}${industry ? `&industry=${industry}` : ''}`);
        if (!res.ok)
            throw new Error('An error occurred while fetching data.');
        return res.json();
    }
    catch (error) {
        throw new Error(`Failed to fetch data: ${error}`);
    }
};
const SearchPage = ({ preloadCount = 10, sections, maxLoadCount = 10, }) => {
    var _a, _b, _c, _d, _e;
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchItem, setSearchItem] = useState({ id: undefined, name: (_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '' });
    const countrySearchParam = (_b = searchParams.get('country')) !== null && _b !== void 0 ? _b : '';
    const [country, setCountry] = useState(isEmpty(countrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(countrySearchParam),
        });
    const industrySearchParam = (_c = searchParams.get('industry')) !== null && _c !== void 0 ? _c : '';
    const [industry, setIndustry] = useState(isEmpty(industrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(industrySearchParam),
        });
    const { data: filtersData, isLoading: isFiltersLoading, isFetching: isFiltersFetching, } = useQuery({
        queryKey: ['filters', country.value, industry.value, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: () => fetchFilterData(contentTypeIds, searchItem.name, country.value, industry.value),
    });
    const contentTypeIds = sections
        .map((sections) => sections.contentTypes)
        .flat();
    const handleFilterChange = (fn, option, searchParamName) => {
        setSearchParams((prev) => {
            var _a, _b;
            const params = new URLSearchParams(prev);
            if (params.get(searchParamName) && !option.value) {
                params.delete(searchParamName);
            }
            else {
                params.set(searchParamName, (_b = (_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
            }
            return params;
        });
        fn(option);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "page-elements__actions container" }, { children: [_jsx("div", Object.assign({ className: "page-elements__actions__search" }, { children: _jsx(Searchbar, { setSearchItem: setSearchItem, contentTypes: contentTypeIds, countrySearchParam: countrySearchParam, industrySearchParam: industrySearchParam }) })), _jsxs("div", Object.assign({ className: "page-elements__actions__filters" }, { children: [_jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Industry', value: undefined }, selected: industry, setSelected: (industry) => handleFilterChange(setIndustry, industry, 'industry'), isLoading: isFiltersLoading || isFiltersFetching, options: (_d = filtersData === null || filtersData === void 0 ? void 0 : filtersData.industries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _d !== void 0 ? _d : [] }), _jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Country', value: undefined }, selected: country, setSelected: (country) => handleFilterChange(setCountry, country, 'country'), isLoading: isFiltersLoading || isFiltersFetching, options: (_e = filtersData === null || filtersData === void 0 ? void 0 : filtersData.countries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _e !== void 0 ? _e : [] }), _jsx("div", Object.assign({ className: "d-flex align-items-end" }, { children: _jsx("a", Object.assign({ href: "/", className: "text--bold text--t2" }, { children: "Clear filters" })) }))] }))] })), _jsx("div", Object.assign({ className: "page-elements__results container" }, { children: sections.map((section) => {
                    var _a;
                    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(SubcategoryErrorComponent, { title: section.title }) }, { children: _jsx(Subcategory, { section: section, limit: preloadCount, searchQuery: (_a = searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) !== null && _a !== void 0 ? _a : '', country: country.value, industry: industry.value, maxLoadCount: maxLoadCount }) }), section.url));
                }) }))] }));
};
export default SearchPage;
