import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, } from 'react';
import { Listbox, OpportunityCard, Pagination, Searchbar, } from '~/components';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { arrayOf, isEmpty } from '~/utils';
import { useSearchParams } from 'react-router-dom';
const SubcategoryPage = ({ preloadCount = 9, pageName, contentTypes, landingPageUrl, iconText, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [searchParams, setSearchParams] = useSearchParams();
    const countrySearchParam = (_a = searchParams.get('country')) !== null && _a !== void 0 ? _a : '';
    const [country, setCountry] = useState(isEmpty(countrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(countrySearchParam),
        });
    const industrySearchParam = (_b = searchParams.get('industry')) !== null && _b !== void 0 ? _b : '';
    const [industry, setIndustry] = useState(isEmpty(industrySearchParam)
        ? {
            label: '',
            value: undefined,
        }
        : {
            label: '',
            value: Number(industrySearchParam),
        });
    const [searchItem, setSearchItem] = useState({ id: undefined, name: (_c = searchParams.get('q')) !== null && _c !== void 0 ? _c : '' });
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
    const { data: filtersData, isLoading: isFiltersLoading, isFetching: isFiltersFetching, } = useQuery({
        queryKey: ['filters', contentTypes, country, industry, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: async () => {
            try {
                const res = await fetch(`/api/v1/mo/filters?contentTypes=${contentTypes}&q=${searchItem === null || searchItem === void 0 ? void 0 : searchItem.name}${country.value ? `&country=${country.value}` : ''}${industry.value ? `&industry=${industry.value}` : ''}`);
                if (!res.ok)
                    throw new Error('An error occurred while fetching data.');
                return res.json();
            }
            catch (e) {
                throw new Error('An error occurred while fetching data.');
            }
        },
    });
    const { data, isLoading, isPlaceholderData, isError } = useQuery({
        queryKey: [contentTypes, page, country, industry, searchItem === null || searchItem === void 0 ? void 0 : searchItem.name],
        queryFn: async () => {
            var _a;
            try {
                const res = await fetch(`/api/v1/mo?contentTypes=${contentTypes}&page=${page}${country.value ? `&country=${country.value.toString()}` : ''}${industry.value ? `&industry=${industry.value.toString()}` : ''}&size=${preloadCount}&q=${(_a = searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) !== null && _a !== void 0 ? _a : ''}`);
                if (!res.ok)
                    throw new Error('An error occurred while fetching data.');
                return res.json();
            }
            catch (e) {
                throw new Error('An error occurred while fetching data.');
            }
        },
        placeholderData: keepPreviousData,
    });
    const onPageChange = (page) => {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('page', page.toString());
            return params;
        });
        setPage(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const handleFilterChange = (fn, option, searchParamName) => {
        setSearchParams((prev) => {
            var _a, _b;
            const params = new URLSearchParams(prev);
            if (params.get(searchParamName) && !option.value) {
                params.delete(searchParamName);
            }
            else {
                params.set(searchParamName, (_b = (_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
            }
            setPage(1);
            params.set('page', '1');
            return params;
        });
        fn(option);
    };
    if (isError) {
        throw new Error('An error occurred while fetching data.');
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "page-elements__actions container" }, { children: [_jsx("div", Object.assign({ className: "page-elements__actions__search" }, { children: _jsx(Searchbar, { setSearchItem: setSearchItem, contentTypes: contentTypes, countrySearchParam: countrySearchParam, industrySearchParam: industrySearchParam }) })), _jsxs("div", Object.assign({ className: "page-elements__actions__filters" }, { children: [_jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Industry', value: undefined }, selected: industry, setSelected: (industry) => handleFilterChange(setIndustry, industry, 'industry'), isLoading: isFiltersLoading || isFiltersFetching, options: (_d = filtersData === null || filtersData === void 0 ? void 0 : filtersData.industries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total == 0,
                                }))) !== null && _d !== void 0 ? _d : [] }), _jsx(Listbox.Autocomplete, { emptyOption: { label: 'Select Country', value: undefined }, selected: country, setSelected: (country) => handleFilterChange(setCountry, country, 'country'), isLoading: isFiltersLoading || isFiltersFetching, options: (_e = filtersData === null || filtersData === void 0 ? void 0 : filtersData.countries.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                    count: item.total,
                                    disabled: item.total === 0,
                                }))) !== null && _e !== void 0 ? _e : [] }), _jsx("div", Object.assign({ className: "d-flex align-items-end" }, { children: _jsx("a", Object.assign({ href: "/", className: "text--bold text--t2" }, { children: "Clear filters" })) }))] }))] })), _jsxs("div", Object.assign({ className: "page-elements__results container category" }, { children: [_jsxs("div", Object.assign({ className: "category__heading" }, { children: [_jsx("h2", Object.assign({ className: "category__heading__title" }, { children: `${pageName} (${!data || isLoading || isPlaceholderData
                                    ? 'Loading...'
                                    : (_f = data === null || data === void 0 ? void 0 : data.totalCount) !== null && _f !== void 0 ? _f : ''}${isEmpty(searchItem === null || searchItem === void 0 ? void 0 : searchItem.name) &&
                                    isEmpty(country.value) &&
                                    isEmpty(industry.value)
                                    ? ''
                                    : ' Found'})` })), _jsx("a", Object.assign({ href: landingPageUrl, className: "category__heading__back-button" }, { children: "Back to frontpage \u00BB" }))] })), isLoading || !data || !data.hits ? (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "category__results" }, { children: arrayOf(preloadCount).map((_, i) => (_jsx(OpportunityCard.Skeleton, {}, i))) })) })) : (data.hits.length !== 0 && (_jsx("div", Object.assign({ className: "category__results" }, { children: data.hits.map((item, key) => (_jsx(OpportunityCard, { opportunity: item, iconText: iconText, isPlaceholder: isPlaceholderData }, `Page${page}/Opportunity${key}`))) })))), !isLoading && data && ((_g = data.hits) === null || _g === void 0 ? void 0 : _g.length) === 0 && data.latest && (_jsxs("div", Object.assign({ className: "category__no-results" }, { children: [_jsx("h3", Object.assign({ className: "heading--no-transform" }, { children: "We didn't find match for your search." })), _jsx("h3", Object.assign({ className: "heading--no-transform" }, { children: "Here are some of our latest in Act Now category." })), _jsx("div", Object.assign({ className: "category__results" }, { children: data.latest.map((item, key) => (_jsx(OpportunityCard, { opportunity: item, iconText: iconText }, `Page${page}/LatestOpportunity${key}`))) }))] })))] })), _jsx("div", Object.assign({ className: "container page-elements__pagination" }, { children: _jsx(Pagination, { currentPage: page, onPageChange: onPageChange, totalPages: Math.ceil(((data === null || data === void 0 ? void 0 : data.totalCount) || 1) / preloadCount), disabled: isLoading || isPlaceholderData }) }))] }));
};
export default SubcategoryPage;
