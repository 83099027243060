import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { Fragment, useEffect } from 'react';
import { arrayOf, isDefined, isEmpty } from '~/utils';
import { useInView } from 'react-intersection-observer';
import { OpportunityCard } from '~/components';
const Subcategory = ({ limit, searchQuery = '', country, industry, section, maxLoadCount = 0, }) => {
    var _a, _b, _c;
    const { ref, inView } = useInView();
    const { data, fetchNextPage, isFetching, isLoading, isFetchingNextPage, isError, } = useInfiniteQuery({
        queryKey: [section.contentTypes, searchQuery, country, industry],
        queryFn: async ({ pageParam }) => {
            try {
                const res = await fetch(`/api/v1/mo?contentTypes=${section.contentTypes}&page=${pageParam !== null && pageParam !== void 0 ? pageParam : 1}${country ? `&country=${country.toString()}` : ''}${industry ? `&industry=${industry.toString()}` : ''}&size=${limit}&q=${searchQuery !== null && searchQuery !== void 0 ? searchQuery : ''}&maxCount=${maxLoadCount}`);
                if (!res.ok)
                    throw new Error('An error occurred while fetching data.');
                return res.json();
            }
            catch (e) {
                throw new Error('An error occurred while fetching data.');
            }
        },
        getNextPageParam: (lastPage) => { var _a; return (_a = lastPage.nextPage) !== null && _a !== void 0 ? _a : undefined; },
        initialPageParam: 1,
        placeholderData: keepPreviousData,
    });
    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
        // Disabling because it wants the fetchNextPage function to be a dependency and that could lead to endless rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);
    if (isError) {
        throw new Error('An error occurred while fetching data.');
    }
    return (_jsxs("section", Object.assign({ className: "subcategory" }, { children: [_jsxs("div", Object.assign({ className: "subcategory__heading" }, { children: [_jsx("h2", Object.assign({ className: "heading--no-transform" }, { children: `${section.title} (${!data && isLoading ? 'Loading...' : (_b = (_a = data === null || data === void 0 ? void 0 : data.pages[0]) === null || _a === void 0 ? void 0 : _a.totalCount) !== null && _b !== void 0 ? _b : ''}${(isEmpty(searchQuery) && isEmpty(country) && isEmpty(industry)) ||
                            isLoading
                            ? ''
                            : ' Found'})` })), ((_c = data === null || data === void 0 ? void 0 : data.pages[0].hits) === null || _c === void 0 ? void 0 : _c.length) === 0 && !isLoading ? (_jsx("span", Object.assign({ className: "subcategory__show-all--disabled" }, { children: "Show all \u00BB" }))) : (_jsx("a", Object.assign({ href: `${section.url}?${isDefined(country) ? `country=${country}` : ''}${isDefined(industry) ? `&industry=${industry}` : ''}${!isEmpty(searchQuery) ? `&q=${searchQuery}` : ''}`, className: `subcategory__show-all text--bold ${isLoading ? 'subcategory__show-all--disabled' : ''}` }, { children: "Show all \u00BB" })))] })), _jsxs("div", Object.assign({ className: "subcategory__carousel" }, { children: [data &&
                        (!data.pages[0].hits || data.pages[0].hits.length === 0) &&
                        !isLoading && _jsx(OpportunityCard.NotFound, { title: section.title }), data &&
                        data.pages.map((page) => {
                            var _a;
                            return page.hits &&
                                page.hits.length > 0 && (_jsxs(Fragment, { children: [(_a = page.hits) === null || _a === void 0 ? void 0 : _a.map((currentItem) => (_jsx(OpportunityCard, { opportunity: Object.assign({}, currentItem), iconText: section.iconText }, `${section.contentTypes}-${currentItem.id}`))), page.maxCountReached && (_jsx(OpportunityCard.ShowMore, { href: `${section.url}?${isDefined(country) ? `country=${country}` : ''}${isDefined(industry) ? `&industry=${industry}` : ''}${!isEmpty(searchQuery) ? `&q=${searchQuery}` : ''}` })), _jsx("div", { ref: ref })] }, `${section.contentTypes}-${page.nextPage}`));
                        }), data &&
                        data.pages.map((page) => {
                            var _a;
                            return page.latest &&
                                page.latest.length > 0 && (_jsxs(Fragment, { children: [(_a = page.latest) === null || _a === void 0 ? void 0 : _a.map((currentItem) => (_jsx(OpportunityCard, { opportunity: Object.assign({}, currentItem), iconText: section.iconText }, `${section.contentTypes}-${currentItem.id}`))), page.maxCountReached && (_jsx(OpportunityCard.ShowMore, { href: `${section.url}?${isDefined(country) ? `country=${country}` : ''}${isDefined(industry) ? `&industry=${industry}` : ''}${!isEmpty(searchQuery) ? `&q=${searchQuery}` : ''}` })), _jsx("div", { ref: ref })] }, `${section.contentTypes}-${page.nextPage}`));
                        }), isLoading || isFetchingNextPage || isFetching
                        ? arrayOf(limit).map((_, i) => (_jsx(OpportunityCard.Skeleton, {}, `${section.contentTypes}-${i}`)))
                        : null] }), section.url)] })));
};
export default Subcategory;
